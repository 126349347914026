/* Overriding the defaults */

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
}

/* Overriding the defaults */

.twoColumn {
  padding: 16px 16px 2px 16px;
  display: flex;
  flex: 1;
}

.twoColumn > * + * {
  border-right-width: 0px;
  border-left-width: 1px;
  border-left: 1px solid rgba(36, 53, 68, 0.12);
}

.twoColumn-noDivider {
  padding-left: 0;
}

.twoColumn-noDivider > * + * {
  border-right-width: 0px;
  border-left-width: 0px;
}

.twoColumn-left {
  padding-right: 8px;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
}

.twoColumn-right {
  padding-left: 8px;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
}

.oneColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  gap: 16px;
}

.oneColumn-top {
  display: flex;
  align-items: center;
}

.oneColumn-top-elements {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.oneColumn-top-iconwithtext {
  display: flex;
  align-items: center;
  gap: 5px;
}
.oneColumn-top-iconwithtext span {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: center;
  color: #243544;
  text-transform: capitalize;
}

.oneColumn-top-results {
  display: flex;
  flex-direction: column;
}

.oneColumn-top-results-value {
  font-size: 38px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.5px;
  text-align: center;
  color: #243544;
}

.oneColumn-top-results-unit {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.17px;
  text-align: center;
  color: #24354461;
}

.oneColumn-result-row-container {
  display: flex;
  flex-direction: column;
}
.oneColumn-result-row {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 0;
}
.oneColumn-result-row span {
  width: 82px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.15px;
  text-align: left;
  text-transform: capitalize;
}
.oneColumn-result-row p {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  color: #243544;
}

.oneColumn-result-row-container > * + * {
  border-top-width: 1px;
  border-bottom-width: 0px;
  border-top: 1px solid rgba(36, 53, 68, 0.12);
}

.container {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: white;
  min-width: 300px;
  width: 383px;
  max-width: 669px;
  min-width: 300px;
  height: 310px;
  box-shadow:
    0px 1px 8px 0px rgba(0, 0, 0, 0.12),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14),
    0px 3px 3px -2px rgba(0, 0, 0, 0.2);
  margin-bottom: 16px;
  position: relative;
}

.widgetHeader-wrapper {
  padding: 16px 16px 8px 16px;
  border-bottom: 1px solid rgba(36, 53, 68, 0.12);
  width: 100%;
}

.widgetHeader-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.widgetHeader-title {
  display: flex;
  flex: 1;
  gap: 15px;
}

.widgetHeader-title h3 {
  color: #243544;
  font-size: 20px;
  line-height: 1.25;
  font-weight: 700;
}

.widgetHeader-icons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.widgetHeader-icons button {
  padding: 4px;
  background-color: transparent;
  border: none;
}

.banner {
  color: #243544;
  line-height: 18px;
  font-size: 13px;
  padding: 3px 10px;
  border-radius: 99999px;
  border: 1px solid rgba(0, 0, 0, 0.26);
  letter-spacing: 0.15px;
  background-color: transparent;
  text-transform: uppercase;
}

.rowItem-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.rowItem-title {
  color: #243544;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.4px;
  text-transform: capitalize;
}

.rowItem-status {
  color: #243544;
  font-size: 14px;
  line-height: 20px;
}

.rowItem-status-true {
  text-align: right;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.rowItem-status-false {
  text-align: right;
  flex-shrink: 0;
}

.rowItem-value {
  color: #243544;
  font-weight: 700;
}

.rowItem-value-noNA {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

.rowItem-value-NA {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.rowItem-unit {
  color: #24354461;
  font-size: 14px;
  line-height: 20px;
  margin-left: 2px;
}

.twoColumn-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.twoColumn-chart-title {
  color: #243544;
  letter-spacing: 0.15px;
  line-height: 24px;
}

.twoColumn-row-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 12px;
}

.twoColumn-row-container > * + * {
  border-top-width: 1px;
  border-bottom-width: 0px;
  border-top: 1px solid rgba(36, 53, 68, 0.12);
}

.widgetHeader-error-badge {
  width: 8px;
  height: 8px;
  border-radius: 999999px;
  background-color: #d32f2f;
  position: absolute;
  top: -1px;
  right: 0;
}

.widgetHeader-badge-container {
  position: relative;
}

.widgetHeader-badge-container-clickable:hover {
  cursor: pointer;
}

.widget-status-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
  text-align: center;
  color: #24354461;
}

.widget-callquality-onecolumn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  flex: 1;
  padding: 16px;
}

.widget-callquality-chart {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.widget-callquality-rows {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.widget-callquality-rows > * + * {
  border-top-width: 1px;
  border-bottom-width: 0px;
  border-top: 1px solid rgba(36, 53, 68, 0.12);
}

.widget-callquality-region {
  margin-top: -12px;
  padding-left: 16px;
  max-width: 40%;
}

.widget-bandwidth-estimatedtime {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.widget-bandwidth-estimatedtime > h1 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: center;
  color: #243544;
  text-transform: capitalize;
}

.widget-bandwidth-estimatedtime > p {
  display: flex;
  flex-direction: column;
}

.widget-bandwidth-estimatedtime > p > span:first-child {
  font-size: 38px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -0.5px;
  text-align: center;
  color: #243544;
}

.widget-bandwidth-estimatedtime > p > span:last-child {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.174px;
  text-align: center;
  color: #24354461;
}
.widget-dns-lookup-wrapper {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.widget-dns-lookup {
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.widget-dns-lookup-row-container {
  flex: 1;
  padding-right: 16px;
}

/* .widget-dns-lookup-row-container span {
  color: #243544;
  line-height: 24px;
  letter-spacing: 0.15px;
} */

.widget-dns-lookup-rows {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.widget-dns-lookup-rows > * + * {
  border-top-width: 1px;
  border-bottom-width: 0px;
  border-top: 1px solid rgba(36, 53, 68, 0.12);
}

.widget-dns-lookup-failed-domain {
  padding-top: 16px;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.widget-dns-lookup-failed-domain p {
  color: #ff494b;
  font-size: 12px;
  line-height: 14.4px;
  letter-spacing: 0.4px;
}
.widget-dns-lookup-failed-domain span {
  color: #243544;
  font-size: 12px;
  line-height: 14.4px;
  letter-spacing: 0.4px;
}

.widget-throughput-wrapper {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  height: 100%;
  padding: 16px 12px;
}
.widget-throughput-bar-wrapper {
  grid-column: span 5 / span 5;
}

.widget-throughput-bar-container {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  height: 100%;
  flex: 1;
  justify-content: center;
}

.widget-throughput-content-wrapper {
  grid-column: span 7 / span 7;
  min-width: 171px;
  margin-left: 28px;
}

.widget-throughput-content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.throughput-row-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 0;
}

.widget-throughput-content-container > * + * {
  border-top-width: 1px;
  border-bottom-width: 0px;
  border-top: 1px solid rgba(36, 53, 68, 0.12);
}

.throughput-row-values {
  display: flex;
  justify-content: space-between;
  gap: 7px;
}

.throughput-row-label {
  color: #243544;
  font-size: 12px;
  line-height: 14.4px;
  letter-spacing: 0.4px;
  text-transform: capitalize;
}

.throughput-row-values h4 {
  flex: 1;
  color: #243544;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.15px;
}

.throughput-row-values h4 span {
  color: rgba(36, 53, 68, 0.38);
  font-size: 12px;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
}

.throughput-bar {
  width: 24px;
  max-width: 24px;
  border-radius: 999px 999px 0 0;
}
.widget-ping-wrapper {
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.widget-ping-wrapper > * + * {
  border-top-width: 1px;
  border-bottom-width: 0px;
  border-top: 1px solid rgba(36, 53, 68, 0.12);
}

.widget-ping-unit {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: #24354461;
}

.widget-ping-all-wrapper {
  padding: 0 16px 0 16px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
  background: white;
}

.widget-ping-all-sticky-row {
  position: sticky;
  top: 0;
  padding: 16px 0 8px 0;
  border-bottom: 1px solid #2435441f;
  background-color: white;
  z-index: 10;
}

.sticky-row {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  background-color: white;
}
.sticky-row p {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.4px;
  text-align: left;
  color: #243544;
}

.widget-ping-all-scroll-content {
  padding-bottom: 30px;
  position: relative;
}

.ping-all-row {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  padding: 8px 0;
}

.ping-all-row p {
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0px;
  color: #243544;
}

.ping-all-row span {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: #24354461;
}

.ping-all-overlay {
  position: absolute;
  bottom: 0;
  z-index: 10;
  height: 26px;
  width: 75%;
  background: linear-gradient(176.37deg, rgba(255, 255, 255, 0) 2.29%, #ffffff 41.88%);
}

.widget-ping-all-scroll-content > * + * {
  border-top-width: 1px;
  border-bottom-width: 0px;
  border-top: 1px solid rgba(36, 53, 68, 0.12);
}

.device-state-wrapper {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0 50px;
  gap: 90px;
}

.device-state-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.device-state-container p {
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #243544;
}

.device-state-container span {
  margin-top: 4px;
  font-family: Roboto;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.17px;
  color: rgba(36, 53, 68, 0.38);
}

.widget-turn-connectivity-wrapper {
  width: 100%;
  height: 100%;
  padding: 16px 2px 16px 16px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.widget-turn-connectivity-wrapper > * + * {
  border-right-width: 0px;
  border-left-width: 1px;
  border-left: 1px solid rgba(36, 53, 68, 0.12);
}

.widget-turn-connectivity-wrapper div {
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.widget-turn-connectivity-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #243544;
}

.widget-turn-connectivity-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.widget-turn-connectivity-icon {
  margin-top: 8px;
  margin-bottom: 16px;
}

.widget-turn-connectivity-values {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.widget-turn-connectivity-values h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;
  color: #243544;
}

.widget-turn-connectivity-values span {
  color: #24354461;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.17px;
  text-align: center;
}

.error-state-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
}

.error-state-values {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.error-state-values h3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;
  color: #243544;
}

.error-state-values p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: center;
  color: rgba(36, 53, 68, 0.68);
  word-break: break-word;
  padding: 0 16px;
}

.error-state-download-link {
  text-decoration: underline;
}
