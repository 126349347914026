.tests-card-content {
  &.additional-tests-card-content {
    padding: 6px 16px;
    td {
      width: unset;
    }
  }

  &--throughput {
  }

  table {
    width: 100%;
    border-collapse: collapse;
    position: relative;
  }

  thead {
  }

  tbody {
  }

  tr {
    &.two-cols-mode-header {
      position: absolute;
      width: 100%;
      top: -30px;
      td {
        font-size: 8px;
        border: none;
        &:first-child {
          width: 130px;
        }
      }
    }
  }

  td {
    height: 40px;
    font-size: 14px;
    // font-weight: 600;
    border-bottom: 1px solid rgba(#c9cbd1, 0.5);
    height: 40px;
    width: 33.33%;
    max-width: 200px;
    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &:first-child {
      text-transform: capitalize;
    }
  }

  th {
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid rgba(#c9cbd1, 0.5);
    height: 40px;
    width: 33.33%;
    text-align: left;
  }

  &--min {
    td {
      width: 66.66%;
      &:first-child {
        width: 33.33%;
      }
    }
  }

  .result-extra {
    font-size: 14px;
  }

  &.tst-video-bandwidth {
    & .tst-bandwidthEstimate {
      & .bandwidth-estimate-row {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &.tst-dns-lookup {
    td {
      width: 56.66%;
    }
  }
}

.title-navis {
  font-weight: 700 !important;
}

.tests-card-rcv-turn-servers {
  align-items: center;
  justify-content: center;
  display: flex;
  margin: auto;
  width: 100%;
  flex-direction: column;
  &__box {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }
  & .empty_box {
    width: unset;

    & .tests-card-rcv-turn-servers__item {
      padding: 10px 20px 15px;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
  }
  &__title {
    display: block;
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 8px;
  }

  &__value {
    display: block;
    text-align: center;
    line-height: 1;
    span {
      display: block;
      font-size: 25px;
    }
  }
}

.tests-card-turn {
  align-items: center;
  justify-content: center;
  display: flex;
  margin: auto;

  &.tst-connectivity {
    flex-direction: column;
  }

  &__box {
    display: flex;
  }

  &__item {
    padding: 10px 20px 15px;
    border-right: 1px solid rgba(#c9cbd1, 0.5);
    &:last-child {
      border-right: 0;
    }
  }

  &__title {
    display: block;
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 8px;
  }

  &__value {
    display: block;
    text-align: center;
    line-height: 1;
    span {
      display: block;
      font-size: 30px;
    }
  }

  &__ms {
    color: #8b909f;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    display: block;
    line-height: 1;
  }

  &__with-proxy {
    margin-top: 5px;
    color: #ff9800;
  }

  &__without-proxy {
    margin-top: 5px;
  }
}

.test-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  text-align: center;
}

.value-row-pair {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.card-container {
  width: 383px;
  min-width: 300px;
  max-width: 669px;
  background: white;
  border-radius: 4px;
  box-shadow:
    0px 1px 8px 0px rgba(0, 0, 0, 0.12),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14),
    0px 3px 3px -2px rgba(0, 0, 0, 0.2);
}

.parent-card .info-icon {
  opacity: 0;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.parent-card:hover .info-icon {
  opacity: 100;
}

.parent-card .log-icon {
  opacity: 0;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.parent-card:hover .log-icon {
  opacity: 100;
}

.line-clamp-1 {
  max-width: 258px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
